<script setup lang="ts">
import type { PopulatedPack, PopulatedInstrument } from '~~/types'

const props = withDefaults(
  defineProps<{
    pack?: PopulatedPack
    instrument?: PopulatedInstrument
    display?: 'small' | 'medium' | 'large'
    playPosition?: 'center' | 'bottom-right'
  }>(),
  {
    display: 'large',
    playPosition: 'center',
  },
)

const { getImageUrl } = useAzBlobUrl()

const item = computed<PopulatedPack | PopulatedInstrument | undefined>(
  () => props.pack || props.instrument,
)

// display pack image if no instrument image found
const image = computed(() => {
  if (props.pack) {
    return props.pack.image
  } else if (props.instrument) {
    return props.instrument.image ? props.instrument.image : props.instrument.pack_id.image
  }
  return undefined
})

// audio player
const audioPlayer = useAudioPlayer()
function playDemo() {
  if (props.instrument) {
    audioPlayer.playAudioDemo({
      demos: props.instrument.demos_mp3,
      name: props.instrument.name,
      image: image.value,
    })
  }
}
</script>

<template>
  <div v-if="item && image" class="dvn-item-cover" :class="display">
    <!-- image -->
    <img class="dvn-item-cover-image" :class="display" :src="getImageUrl(image)" :alt="item.name" />

    <!-- click area -->
    <div
      v-if="instrument"
      class="dvn-item-cover-click-area"
      :class="playPosition"
      @click="playDemo()"
    >
      <VIcon
        v-if="instrument.demos_mp3 && instrument.demos_mp3.length"
        icon="fa-regular:play-circle"
        class="dvn-item-cover-play"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
/* container */
div.dvn-item-cover {
  position: relative;
}

div.dvn-item-cover.small {
  width: 8em;
}

div.dvn-item-cover.medium {
  width: 12em;
}

div.dvn-item-cover.large {
  width: 16em;
}

/* image */
img.dvn-item-cover-image {
  border-radius: 0.3em;
}

img.dvn-item-cover-image.small {
  width: 8em;
  height: 8em;
  border-radius: 0 0 0.3em 0;
}

img.dvn-item-cover-image.medium {
  width: 12em;
  height: 12em;
}

img.dvn-item-cover-image.large {
  width: 16em;
  height: 16em;
}

/* click area */
div.dvn-item-cover-click-area {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0);
  transition: background-color var(--dvn-transition-time) var(--dvn-transition-mode);
}

div.dvn-item-cover-click-area.center {
  top: 0;
  left: 0;
}

div.dvn-item-cover-click-area.bottom-right {
  right: 0;
  bottom: 0;
}

div.dvn-item-cover-click-area:hover {
  cursor: pointer;
}

div.dvn-item-cover-click-area.center:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

/* play icon */
svg.dvn-item-cover-play {
  width: 2.3em;
  height: 2.3em;
  color: rgba(255, 255, 255, 0.5);
  transition: color var(--dvn-transition-time) var(--dvn-transition-mode);
}

div.dvn-item-cover-click-area:hover svg.dvn-item-cover-play {
  color: rgba(255, 255, 255, 1);
}
</style>
